<template>
  <div>
    <ag-table
      :grid-options="gridOptions"
      :row-data="rowData"
      @emitAddNewRecord="createRecord()"
      @emitDeleteMultipleRecords="deleteVariant($event, null, true)"
    />

    <vs-popup
      title="تفاصيل الخصائص"
      :active.sync="popupActive"
    >
      <vs-progress
        v-if="isLoading"
        indeterminate
        color="primary"
      />
      <ValidationObserver
        v-slot="{ handleSubmit }"
        ref="validationObserver"
      >
        <form @keydown.enter.prevent="handleSubmit(onSubmit)">
          <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full">
              <ValidationProvider
                name="اسم الخاصية"
                rules="required"
                v-slot="{ errors }"
              >
                <label>اسم الخاصية</label>
                <vs-input
                  placeholder="اسم الخاصية"
                  v-model="variant.name"
                  class="w-full"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="vx-col sm:w-1/2 w-full">
              <tm-select
                name="المنتج"
                :options="products"
                validation-rules="required"
                v-model="variant.product_id"
                class="w-full mb-6"
                label="name"
                :reduce="name => name.id"
              />
            </div>
          </div>
          <div class="vx-row mt-5">
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <vs-button
                :disabled="isLoading"
                ycolor="success"
                type="filled"
                @click.prevent="handleSubmit(onSubmit)"
              >
                حفظ
              </vs-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vs-popup>

    <vs-popup
      title="تفاصيل الخصائص"
      :active.sync="popupActiveDetails"
    >
      <form>
        <div class="vx-row mt-10">
          <div class="vx-col sm:w-1/4 w-full">
            <h4>اسم الخاصية :</h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4>{{ variant.name }}</h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <h4>معرّف المنتج :</h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4>{{ variant.product_id }}</h4>
          </div>
        </div>

        <div class="vx-row mt-5">
          <div class="vx-col sm:w-1/2 w-full mb-2">
            <vs-button
              ycolor="success"
              type="filled"
              @click="popupActiveDetails = false"
            >
              حسناً
            </vs-button>
          </div>
        </div>
      </form>
    </vs-popup>
  </div>
</template>

<script>
import AgTable from "../../../shared/shared-components/ag-grid/AgTable.vue";
import AgTableBtnCell from "../../../shared/shared-components/ag-grid/AgTableBtnCell";
import {RepositoryFactory} from "../../repositories/ecommerceRepositoryFactory";
import utilities from "../../../shared/utilities";
import AgButton from "../../../shared/shared-components/ag-grid/AgButton";
import TmSelect from "../../../shared/shared-components/TmSelect";

const variantRepo = RepositoryFactory.get("variantKey");
const productRepo = RepositoryFactory.get("product");

export default {
  name: "VariantKey",
  components: {
    AgTable,
    TmSelect
  },
  data() {
    return {
      gridOptions: null,
      rowData: [],
      variant: {
        name: "",
        product_id: null,
      },
      products: [],
      popupActive: false,
      popupActiveDetails: false,
      isLoading: true,
    };
  },
  methods: {
    createColDefs() {
      let self = this;
      return [
        {
          headerName: "المعرف",
          field: "id",
          filter: 'agTextColumnFilter',
          suppressSizeToFit: true,
          width: 225,
          headerCheckboxSelection: true,
          checkboxSelection: true,
        },
        {
          headerName: "الاسم",
          field: "name",
          filter: 'agTextColumnFilter',
        },
        {
          headerName: "المنتج",
          field: "product.name",
          filter: 'agTextColumnFilter',
        },
        {
          headerName: "الخصائص الفرعية",
          cellRendererFramework: AgButton,
          field: "id",
          filter: false,
          cellRendererParams: {
            size: "small",
            color: "primary",
            name: "مشاهدة الخصائص الفرعية",
            type: "gradient",
            click(id) {
              self.rowClicked(id);
            }
          },
        },
        {
          headerName: "الإجراءات",
          field: "id",
          cellRendererFramework: AgTableBtnCell,
          cellRendererParams: {
            deleteRecord: function (id, rowIndex) {
              self.deleteVariant(id, rowIndex);
            },
            editRecord: function (variant) {
              self.editRecord(variant);
            },
            actions: ["edit", "delete"],
          },
        },
      ];
    },

    deleteVariant(ids, index, isMultiple = false) {
      this.isLoading = true;
      if (isMultiple) ids = ids.join(",");
      variantRepo.deleteVariant(ids).then((response) => {
        if (response.code === 200) {
          if (isMultiple) this.getAllVariant();
          else {
            this.rowData.splice(index, 1);
            this.rowData = [...this.rowData];
          }
        }
        this.isLoading = false;
      });
    },
    rowClicked(id) {
      this.$router.push(`/ecommerce/variant/${id}/variant_value`);
    },

    // used to fire the dialog which response to add a new record in the table
    createRecord() {
      utilities.initObjectMembers(this.variant);
      this.openPopup();
    },

    // used to fire the dialog which response to edit current record in the table
    editRecord(variant) {
      let data = {
        id: variant.id,
        name: variant.name,
        product_id: variant.product.id
      };
      Object.assign(this.variant, data);
      this.openPopup();
    },

    openPopup() {
      this.$refs.validationObserver.reset();
      this.popupActive = true;
    },

    onSubmit() {
      this.isLoading = true;

      if (this.variant.id) {
        this.updateVariant(this.variant);
      } else {
        this.storeVariant(this.variant);
      }
    },

    storeVariant(variant) {
      variantRepo.storeVariant(variant).then((response) => {
        this.rowData.unshift(response.data);
        this.closeLoading();
        this.isLoading = false;
      });
    },

    updateVariant(variant) {
      variantRepo.updateVariant(variant).then((response) => {
        let index = this.rowData.findIndex((variant) => {
          return variant.id === response.data.id;
        });
        this.rowData[index] = response.data;
        this.rerender();
        this.closeLoading();
        this.isLoading = false;
      });
    },

    closeLoading() {
      this.popupActive = false;
    },

    // used to rerender updated row
    rerender() {
      this.gridApi.setRowData(this.rowData);
    },

    getAllVariant() {
      variantRepo.getAllVariant().then((response) => {
        this.rowData = response;
        this.isLoading = false;
      });
    }
  },
  created() {
    // prepare ag grid options
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
    this.getAllVariant();

    productRepo.getAllProducts().then((response) => {
      this.products = response.data;
    });
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  },
};
</script>
